import { formatNumber } from "./number";

/**
 * Will scale an element to fit within its container with a desired spacing
 * while preserving aspect ratio.
 *
 * @param el - The element to scale
 * @param spacing - The desired spacing (in pixels) between the element and its container. Must be >= 0.
 * @returns
 */
export function scaleElement(el: HTMLElement | null, spacing = 24): void {
  if (!el || spacing < 0) {
    return;
  }
  const internalEl = el;
  const { width, height } = internalEl.getBoundingClientRect();
  if (width <= 0 || height <= 0) {
    return;
  }
  // Formula: 1 - ((desiredSpacing / dimension)
  // We format to 2 decimal places to avoid ridiculous precision
  const scaleX = formatNumber(1 - spacing / width, 2);
  const scaleY = formatNumber(1 - spacing / height, 2);
  internalEl.style.scale = `${scaleX} ${scaleY}`;
}
