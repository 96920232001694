import { get, set } from "lodash";

import { AssessConfiguration, ScoreFactor } from "fond/types";

export type ScoringInputConfig = {
  id:
    | "AddressDensityImportance"
    | "AddressUnderservedProportionImportance"
    | "ProviderFiberCoverageImportance.AT&T"
    | "ProviderFiberCoverageImportance.T-Mobile"
    | "ProviderFiberCoverageImportance.Verizon"
    | "WeightedMedianIncomeImportance"
    | "AddressBreakdownImportance.Sdu";
  title: string;
  description: string;
  factor: ScoreFactor;
};

export const scoringInputConfigs: ScoringInputConfig[] = [
  {
    id: "AddressDensityImportance",
    title: "Street Frontage",
    factor: "cost",
    description: "Subarea score will increase as average street frontage decreases",
  },
  {
    id: "AddressUnderservedProportionImportance",
    title: "Proportion underserved",
    factor: "revenue",
    description: "Subarea score will increase as the underserved proportion increases",
  },
  {
    id: "ProviderFiberCoverageImportance.AT&T",
    title: "Avoid AT&T",
    factor: "revenue",
    description: "Subarea score will be higher in areas where AT&T has less fiber coverage",
  },
  {
    id: "ProviderFiberCoverageImportance.T-Mobile",
    title: "Avoid T-Mobile",
    factor: "revenue",
    description: "Subarea score will be higher in areas where T-Mobile has less fiber coverage",
  },
  {
    id: "ProviderFiberCoverageImportance.Verizon",
    title: "Avoid Verizon",
    factor: "revenue",
    description: "Subarea score will be higher in areas where Verizon has less fiber coverage",
  },
  {
    id: "WeightedMedianIncomeImportance",
    title: "Median Income",
    factor: "revenue",
    description: "Subarea score will increase as median income increases",
  },
  {
    id: "AddressBreakdownImportance.Sdu",
    title: "SDU Proportion",
    factor: "revenue",
    description: "Subarea score will increase as the SDU proportion increases",
  },
];

/**
 * The default values to apply to get someone started with scoring inputs.
 * These are the values that will be applied when a user first opens the scoring panel and all the values are null.
 */
const defaultAssessConfigurationFieldValues: Partial<Record<ScoringInputConfig["id"], number>> = {
  AddressDensityImportance: 0.5,
  AddressUnderservedProportionImportance: 0.5,
  "ProviderFiberCoverageImportance.AT&T": 0.5,
  WeightedMedianIncomeImportance: 0.5,
};

/**
 * Will get a default assess configuration with all values set to null except those defined in defaultAssessConfigurationFieldValues.
 * Will return an {@link AssessConfiguration} from the configured default fieldname/value map.
 */
export const getDefaultAssessConfiguration = (): AssessConfiguration => {
  const baseConfig = {
    ...scoringInputConfigs.reduce((acc, item) => set(acc, item.id, null), {}),
  };
  Object.entries(defaultAssessConfigurationFieldValues).forEach(([id, defaultValue]) => set(baseConfig, id, defaultValue ?? null));
  return baseConfig as AssessConfiguration;
};

export const DEFAULT_SCORING_INPUT_VALUE = 0.5;

/**
 * Check if the given assess configuration has any values, optionally filtered by factor
 * @param assessConfiguration - the assess configuration to check
 * @param factor - the factor to filter by
 */
export const assessConfigurationHasValues = (assessConfiguration: AssessConfiguration | undefined, factor?: ScoreFactor): boolean => {
  const foundValues = scoringInputConfigs.filter((item) => get(assessConfiguration, item.id) !== null);
  if (!factor) {
    return foundValues.length > 0;
  }
  return getFactorScoringInputConfig(factor, foundValues).length > 0;
};

/**
 * Get the scoring input configuration for a given factor
 * @param factor
 * @param configToFilter - the configuration to filter, defaults to the full scoring input configuration
 */
export const getFactorScoringInputConfig = (factor: ScoreFactor, configToFilter = scoringInputConfigs): ScoringInputConfig[] => {
  return configToFilter.filter((item) => item.factor === factor);
};
