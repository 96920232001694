import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePrevious } from "react-use";
import { Check } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Typography } from "@mui/material";
import classNames from "classnames";

import localStorage from "fond/utils/localStorage";
import { SpinningCircle } from "fond/widgets";

import "./AccordionStepper.scss";

type ClassSet = { optional: boolean; completed: boolean; current: boolean; disabled: boolean; loading: boolean };

export type AccordionStepperPane = {
  header: string;
  id: string;
  content: React.ReactNode;
  rightAdornment?: React.ReactNode;
  isComplete: boolean;
  isLoading?: boolean;
  optional?: boolean;
};

interface AccordionStepperProps {
  id: string;
  panes: AccordionStepperPane[];
  /**
   * Move to the next step when the current step is complete. Can either be
   * a boolean or a array of pane ids. Any pane id specified will be automatically transitions from when completed.
   * @default true
   */
  autoMove?: boolean | string[];
  variant?: "standard" | "outlined";
}

const AccordionStepper = ({ id, panes, autoMove = true, variant = "standard" }: AccordionStepperProps): React.ReactNode => {
  const accordionLocalStorageKey = `state.project.city[${id}].accordion`;

  /**
   * Determine the starting index of the accordion based on which steps are completed
   * (move to the first uncompleted step).
   */
  const startingIndex = useMemo(() => panes.indexOf(panes.at(panes.findIndex(({ isComplete }) => !isComplete))!), [panes]);
  const previousStartingIndex = usePrevious(startingIndex);

  /**
   * Determines if the step should be disabled or not based on the completed & optional step values
   */
  const getIsDisabled = useCallback(
    (index: number) => {
      const completed = panes.map(({ isComplete, optional }) => isComplete || optional).slice(0, index);

      // First item is never disabled
      if (index === 0) return false;

      // Completed steps are never disabled
      if (completed[index]) return false;

      // If all previous steps are completed or optional
      if (!completed.some((value) => !value)) {
        return false;
      }

      return true;
    },
    [panes]
  );

  /**
   * For each step in the accordion determine its status.
   */
  const stepClassSets = useMemo((): ClassSet[] => {
    const completed = panes.map(({ isComplete }) => isComplete);
    const loading = panes.map(({ isLoading }) => isLoading);

    return panes.map(({ optional }, i) => {
      return {
        optional: !!optional,
        completed: completed[i],
        current: !getIsDisabled(i),
        disabled: getIsDisabled(i),
        loading: !!loading[i],
      };
    });
  }, [getIsDisabled, panes]);

  const [activeIndex, setActiveIndex] = useState(localStorage.getItem(accordionLocalStorageKey)?.activeIndex ?? startingIndex);
  const setAccordionIndex = useCallback(
    (index: number) => {
      setActiveIndex(index);
      localStorage.setItem(accordionLocalStorageKey, { activeIndex: index });
    },
    [accordionLocalStorageKey]
  );

  /**
   * Handles the expanding of the clicked according item if that item is not disabled.
   */
  const handleOnClickIndex = useCallback(
    (index: number) => {
      if (index !== activeIndex) {
        if (!stepClassSets[index].disabled) {
          setAccordionIndex(index);
        }
      }
    },
    [activeIndex, setAccordionIndex, stepClassSets]
  );

  /**
   * Monitor for step completion and auto process if set
   */
  useEffect(() => {
    if (previousStartingIndex !== undefined && previousStartingIndex < startingIndex && autoMove) {
      if (autoMove === true || (Array.isArray(autoMove) && autoMove.includes(panes[previousStartingIndex].id))) {
        handleOnClickIndex(startingIndex);
      }
    }
  }, [autoMove, handleOnClickIndex, panes, previousStartingIndex, startingIndex]);

  const getStepInner = (index: number, completed: boolean, isLoading: boolean) => {
    if (variant === "standard") return index + 1;
    if (completed && !isLoading) return <Check />;
    return null;
  };

  return (
    <Box className={`accordion-stepper accordion customScrollbars accordion-${variant}`}>
      {panes.map((pane, i) => (
        <Accordion key={`${pane.id}_title`} data-testid={`accordion-pane-${pane.id}`} expanded={activeIndex === i} elevation={0}>
          <AccordionSummary
            onClick={() => handleOnClickIndex(i)}
            style={{
              minHeight: "auto",
              margin: 0,
              display: "flex",
              flexDirection: "row",
            }}
            disabled={stepClassSets[i].disabled}
            className={classNames("title", { ...stepClassSets[i] }, { active: activeIndex === i })}
          >
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <div className="accordion-stepper-step-number-circle">
                <div className="accordion-stepper-step-number-circle__inner">
                  <div className="accordion-stepper-step-number-circle__number">
                    {getStepInner(i, stepClassSets[i].completed, stepClassSets[i].loading)}
                  </div>
                  {stepClassSets[i].loading && (
                    <div className="accordion-stepper-step-number-circle__spinning-circle-container">
                      <SpinningCircle />
                    </div>
                  )}
                </div>
              </div>
              <Box flexGrow={1}>
                <Typography variant="body1" className="accordion-panel__title">
                  {pane.header}
                </Typography>
              </Box>
              <Box>
                {pane.rightAdornment ||
                  (pane.optional && pane.rightAdornment !== null && <Chip label="optional" size="small" sx={{ fontSize: 11 }} />)}
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails key={`${pane.id}_content`} className="accordion-panel__content" style={{ margin: 0 }}>
            {pane.content}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default AccordionStepper;
