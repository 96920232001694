import { createEntityAdapter, createSelector, EntityState } from "@reduxjs/toolkit";

import { apiSlice } from "fond/api/apiSlice";
import { Banner, Store } from "fond/types";

type GetBannersResponse = {
  Banners: Banner[];
};

export const bannersAdapter = createEntityAdapter<Banner>({
  selectId: (entity: Banner): string => entity.ID,
});
const bannersInitialState = bannersAdapter.getInitialState();

export const bannerSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getBanners: build.query<EntityState<Banner>, string | undefined>({
      query: () => ({
        url: "/v2/banners",
      }),
      transformResponse: (response: GetBannersResponse) => {
        const banners = response.Banners.map((banner): Banner => ({ ...banner, Content: JSON.parse(banner.Content) }));
        return bannersAdapter.setMany(bannersInitialState, banners);
      },
      providesTags: (result) =>
        result ? [...result.ids.map((id) => ({ type: "Banner" as const, id })), { type: "Banner", id: "LIST" }] : [{ type: "Banner", id: "LIST" }],
    }),
  }),
});

export const { useGetBannersQuery, useLazyGetBannersQuery } = bannerSlice;

const selectBannersResult = bannerSlice.endpoints.getBanners.select(undefined);
const selectBannersData = createSelector(selectBannersResult, (bannersResult) => bannersResult.data);

export const { selectAll: selectAllBanners, selectById: selectBannerById } = bannersAdapter.getSelectors(
  (state: Store) => selectBannersData(state) ?? bannersInitialState
);
