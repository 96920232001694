import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

import { BlockSpinner } from "fond/widgets";

interface IProps {
  title: string;
  icon: React.ReactNode;
  value: string;
  unit?: string;
  isLoading?: boolean;
  cellHeight?: number;
}

const CityPlannerInfoCard: React.FC<IProps> = ({ title, icon, value, unit, cellHeight, isLoading }) => {
  let displayValue: JSX.Element | string = value;
  let displayUnit: typeof unit = unit;

  if (isLoading && value === "0") {
    displayValue = <BlockSpinner spinnerProps={{ size: 20 }} />;
    displayUnit = undefined;
  }

  return (
    <Grid item xs={6} sx={{ height: cellHeight ?? 160 }}>
      <Paper variant="elevation" elevation={1} square={false} sx={{ display: "flex", flexDirection: "column", height: "100%", p: 3 }}>
        <Typography variant="h7" fontWeight={500}>
          {title}
        </Typography>
        <Box flex={1} display="flex" alignItems="center">
          {icon}
          <Typography variant="h3" component="span" sx={{ ml: 2, mr: 1 }}>
            {displayValue}
          </Typography>
          <Typography variant="h7" fontWeight={500}>
            {displayUnit}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
};

export default CityPlannerInfoCard;
