import React, { useMemo } from "react";
import { ColDef, GridReadyEvent, SelectionChangedEvent, SortChangedEvent, ValueGetterParams } from "@ag-grid-community/core";

import { Attachment } from "fond/types";

import { AgGrid, fileSizeValueFormatter, LinkCellRenderer, MimeTypeCellRenderer, stringComparator } from "../AgGrid";

import { Sort } from "./types";

interface IProps {
  files: Attachment[];
  columnDefs?: ColDef[];
  onSelectionChange(files: Attachment[]): void;
  handleZoomOpen(index: number): void;
  selected: Attachment[];
  pagination?: boolean;
  sort: Sort | null;
  setSort(value: Sort | null): void;
}

const ListView: React.FC<IProps> = ({ files, columnDefs, onSelectionChange, handleZoomOpen, selected, pagination, sort, setSort }) => {
  const selectedFileIds = selected.map(({ ID }) => ID);

  const defaultColumns: ColDef[] = useMemo(
    () => [
      {
        field: "MimeType",
        headerName: "Type",
        minWidth: 80,
        width: 90,
        suppressHeaderMenuButton: true,
        cellRenderer: MimeTypeCellRenderer,
        cellClass: "vertically-align",
        resizable: false,
      },
      {
        field: "Name",
        headerName: "Name",
        minWidth: 100,
        flex: 1,
        suppressHeaderMenuButton: true,
        valueGetter: (params: ValueGetterParams) => `${params.data.Name}.${params.data.Extension}`,
        cellRenderer: LinkCellRenderer,
        cellRendererParams: {
          onClick: (targetFile: Attachment) => () => {
            const index = files.findIndex((file) => file.ID === targetFile.ID);
            handleZoomOpen(index);
          },
        },
        comparator: stringComparator,
      },
      {
        field: "Size",
        headerName: "Size",
        valueFormatter: fileSizeValueFormatter,
        width: 80,
        type: "rightAligned",
        suppressHeaderMenuButton: true,
      },
    ],
    [files, handleZoomOpen]
  );

  /**
   * Set the 'selectedItems' state variable from the IDs of the currently selected row items.
   */
  const handleOnSelection = (event: SelectionChangedEvent) => {
    const newSelection: Attachment[] = [];
    event.api.forEachNode((node) => {
      if (node.isSelected()) {
        newSelection.push(node.data);
      }
    });
    onSelectionChange(newSelection);
  };

  const handleOnSortChanged = (event: SortChangedEvent) => {
    if (!event.columns) return;
    const changedColumn = event.columns[0];
    const columnId = changedColumn.getColId() as keyof Attachment;
    const columnSort = changedColumn.getSort();
    if (columnSort == null) {
      setSort(null);
    } else {
      setSort({ colId: columnId, sort: columnSort });
    }
  };

  const handleOnGridReady = (event: GridReadyEvent) => {
    // Handle selection of currently checked files
    event.api.forEachNode((node) => {
      if (selectedFileIds.includes(node.data.ID)) {
        node.setSelected(true);
      }
    });

    // Handle currently applied sorting
    if (sort) {
      event.api.applyColumnState({ state: [sort] });
    }
  };

  return (
    <AgGrid
      columnDefs={columnDefs ?? defaultColumns}
      rowData={files}
      gridOptions={{
        animateRows: true,
        rowGroupPanelShow: "never",
        rowSelection: {
          mode: "multiRow",
          checkboxes: true,
        },
        sideBar: false,
        paginationAutoPageSize: pagination,
        pagination,
        suppressMultiSort: true,
        suppressMovableColumns: true,
      }}
      onSelectionChanged={handleOnSelection}
      onSortChanged={handleOnSortChanged}
      variant="outlined"
      onGridReady={handleOnGridReady}
    />
  );
};

export default ListView;
