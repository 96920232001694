import {
  Box,
  checkboxClasses,
  css,
  FormControlLabel,
  formControlLabelClasses,
  ImageList as BaseImageList,
  ImageListItem,
  ImageListItemBar as BaseImageListItemBar,
  imageListItemBarClasses,
  styled,
} from "@mui/material";

import { visuallyHiddenCss } from "fond/utils/css";

export const ImageList = styled(BaseImageList)(({ theme }) => ({
  padding: theme.spacing(3),
  /**
   * Below overrides the inline style "grid-template-columns: repeat(2, 1fr)"
   * that MUI applies to ImageList on render. This is shorthand for "grid-template-columns: repeat(2, minmax(auto, 1fr))"
   * that allows images in the grid to have width beyond 1 fraction of the space
   */
  gridTemplateColumns: "repeat(2, minmax(0, 1fr)) !important",
}));

export const ImageListItemBar = styled(BaseImageListItemBar)`
  text-align: center;

  & .${imageListItemBarClasses.title} {
    font-size: 12px;
  }
  & .${imageListItemBarClasses.subtitle} {
    font-size: 10px;
  }
`;

export const GalleryImageListItem = styled(ImageListItem)`
  &:hover,
  &:focus-within {
    .${`${checkboxClasses.root}:not(.${checkboxClasses.checked})`} {
      opacity: 1;
      svg {
        color: ${({ theme }) => theme.palette.biarri.secondary.whiteSmoke};
        box-shadow: inset 0px 0px 2px 0 rgba(0, 0, 0, 0.6);
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
`;

export const ImageWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected: boolean }>(
  ({ isSelected, theme }) => css`
    position: relative;
    cursor: zoom-in;
    font-size: 0;
    text-align: center;
    width: 100%;
    min-height: 50px;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    img,
    .non-image-placeholder {
      transition: scale 0.1s ease-in-out;
    }
  `
);

export const ImagePlaceholder = styled(Box)`
  position: relative;
  border-radius: 2px;
  background: ${({ theme }) => theme.palette.biarri.secondary.whiteSmoke};
  display: grid;
  place-items: center;
  aspect-ratio: 1;
  color: ${({ theme }) => theme.palette.biarri.secondary.activeGrey};
`;

export const SelectFileLabel = styled(FormControlLabel)`
  position: absolute;
  display: block;
  text-align: left;
  top: 0;
  left: 0;
  padding: ${({ theme }) => theme.spacing(1.5)};
  padding-top: 0;

  & .${formControlLabelClasses.label} {
    ${visuallyHiddenCss}
  }
  & .${checkboxClasses.root} {
    opacity: 0;
    padding: ${({ theme }) => theme.spacing(0.5)};
  }
  & .${checkboxClasses.checked}, &:hover .${checkboxClasses.root}, &:focus-visible .${checkboxClasses.root} {
    opacity: 1;
  }

  &:hover,
  &:focus-within,
  &:focus-visible {
    .${checkboxClasses.root} {
      svg {
        color: ${({ theme }) => theme.palette.biarri.secondary.darkCarbon};
      }
    }
  }
`;
