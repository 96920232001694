import React from "react";
import { useForm } from "react-final-form";
import { Delete } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { get } from "lodash";

import { TableCell, TableRow } from "ui";

import { ScoringInputConfig, scoringInputConfigs } from "fond/cityPlanner/assessConfigurationHelper";
import { AssessConfiguration, ScoreFactor } from "fond/types";

import ScoringInputForm from "./ScoringInputForm";

interface ScoringInputProps {
  config?: AssessConfiguration;
  factor?: ScoreFactor;
}

const ScoringInputs: React.FC<ScoringInputProps> = ({ config, factor }: ScoringInputProps) => {
  const { getFieldState } = useForm();

  /**
   * Deleting a field is analogous to setting it to null so that it is not explicitly set to that value and effectively
   * removed from consideration in the scoring.
   */
  const deleteField = (id: string) => {
    getFieldState(id)?.change(null);
  };

  const getInput = ({ id, title, description }: ScoringInputConfig) => {
    const configValueForId = get(config, id);
    if (configValueForId == null) {
      return null;
    }

    return (
      <TableRow key={id}>
        <TableCell width={125}>
          <Tooltip title={description} placement="right-start" describeChild>
            <span>{title}</span>
          </Tooltip>
        </TableCell>
        <TableCell>
          <ScoringInputForm id={id} />
        </TableCell>
        <TableCell>
          <IconButton size="small" color="primary" aria-label="Delete" onClick={() => deleteField(id)}>
            <Delete fontSize="small" />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  return scoringInputConfigs.filter((input) => (factor ? input.factor === factor : true)).map(getInput);
};

export default ScoringInputs;
