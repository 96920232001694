import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, FormSpy } from "react-final-form";
import { GridOptions } from "@ag-grid-community/core";
import { Undo } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

import { LoadingButton, Table, TableBody, TableCell, TableHead, TableRow } from "ui";
import { Accordion } from "ui/Accordion";

import { multiProjectsSlice, useGetMultiProjectQuery, useLazyGetMultiProjectQuery, useUpdateMultiProjectMutation } from "fond/api";
import { assessConfigurationHasValues, getDefaultAssessConfiguration } from "fond/cityPlanner/assessConfigurationHelper";
import { AssessConfiguration, MultiProjectArea } from "fond/types";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { AgGrid, BlockSpinner, useStackedNavigationContext } from "fond/widgets";

import { FactorCountBadge } from "./FactorCountBadge";
import { rankingColumns } from "./rankingColumns";
import ScoringInputs from "./ScoringInputs";
import { ScoringItemsMenu } from "./ScoringItemsMenu";

type ScoringFormData = AssessConfiguration;

const ScoringPanel: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const versionId = useAppSelector((state) => state.project.versionId);
  const { data: multiProject } = useGetMultiProjectQuery(versionId);
  const [getMultiProject, { isFetching }] = useLazyGetMultiProjectQuery();
  const [updateMultiProject, { isLoading }] = useUpdateMultiProjectMutation();
  const { goBack } = useStackedNavigationContext();

  const [initialValuesApplied, setInitialValuesApplied] = useState(false);

  const handleBack = async () => {
    // Manually refresh the multiproject data to restore to saved version.
    await getMultiProject(versionId, false).unwrap();
    setInitialValuesApplied(false);
    goBack();
  };

  const handleOnReset = async () => {
    await getMultiProject(versionId, false).unwrap();
  };

  const handleOnSave = async (values: ScoringFormData) => {
    if (multiProject) {
      try {
        await updateMultiProject({ ID: multiProject.ID, AssessConfiguration: values });
        enqueueSnackbar("Scoring inputs have been updated.");
        goBack();
      } catch (e) {
        enqueueSnackbar("Scoring update failed. Please try again...");
      }
    }
  };

  const handleOnFormChange = useCallback(
    (values: ScoringFormData) => {
      if (multiProject) {
        dispatch(
          multiProjectsSlice.util.updateQueryData("getMultiProject", multiProject.ID, (draft) => {
            draft.AssessConfiguration = values; // eslint-disable-line no-param-reassign
          })
        );
      }
    },
    [dispatch, multiProject]
  );

  const hasConfig = useMemo(() => {
    return assessConfigurationHasValues(multiProject?.AssessConfiguration);
  }, [multiProject?.AssessConfiguration]);

  const assessConfiguration = useMemo(() => {
    if (hasConfig || initialValuesApplied) {
      return multiProject?.AssessConfiguration;
    }
    if (!initialValuesApplied) {
      return getDefaultAssessConfiguration();
    }
    return null;
    // initialValuesApplied is omitted on purpose to avoid
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [multiProject?.AssessConfiguration, hasConfig]);

  // If the configuration is empty, we need to set some default values to help users get started.
  // we also trigger the form change to update the state with the default values.
  useEffect(() => {
    if (assessConfiguration && !initialValuesApplied) {
      setInitialValuesApplied(true);
      handleOnFormChange(assessConfiguration);
    }
  }, [assessConfiguration, handleOnFormChange, initialValuesApplied]);

  const gridOptions: GridOptions<MultiProjectArea> = {
    animateRows: true,
    editType: "fullRow",
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    singleClickEdit: true,
    suppressMovableColumns: true,
    domLayout: "normal",
    getRowId: (params) => params.data.ID,
  };

  if (!multiProject) {
    return <BlockSpinner />;
  }

  return (
    <Box>
      <Form<ScoringFormData>
        initialValues={assessConfiguration}
        onSubmit={handleOnSave}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} data-testid="assess-scoring-form" id="assess-scoring-form">
            <Accordion label="Cost factors" sx={{ mb: 2 }} id="cost-factors" endAdornment={<FactorCountBadge factor="cost" />} defaultExpanded>
              <Table variant="ag-grid-compact">
                <TableHead>
                  <TableRow>
                    <TableCell>Criteria</TableCell>
                    <TableCell width={100} colSpan={2}>
                      Importance
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ScoringInputs config={assessConfiguration} factor="cost" />
                  <TableRow>
                    <TableCell colSpan={3} padding="none">
                      <ScoringItemsMenu id="revenue-factors-menu" factor="cost" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Accordion>
            <Accordion label="Revenue factors" id="revenue-factors" endAdornment={<FactorCountBadge factor="revenue" />} defaultExpanded>
              <Table variant="ag-grid-compact">
                <TableHead>
                  <TableRow>
                    <TableCell>Criteria</TableCell>
                    <TableCell width={100} colSpan={2}>
                      Importance
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <ScoringInputs config={assessConfiguration} factor="revenue" />
                  <TableRow>
                    <TableCell colSpan={3} padding="none">
                      <ScoringItemsMenu id="revenue-factors-menu" factor="revenue" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Accordion>
            {/* We use FormSpy to submit the form values on every update */}
            <FormSpy<ScoringFormData>
              subscription={{ values: true, pristine: true }}
              onChange={({ pristine, values }) => {
                if (!pristine) {
                  handleOnFormChange(values);
                }
              }}
            />
          </form>
        )}
      />

      <Typography variant="h6" sx={{ mt: 2, fontSize: 14, fontWeight: 500 }}>
        Rankings
      </Typography>
      <AgGrid
        containerProps={{
          height: 300,
        }}
        columnDefs={rankingColumns}
        rowData={multiProject?.Areas || null}
        size="compact"
        variant="outlined"
        gridOptions={gridOptions}
      />
      <Divider sx={{ mt: 3 }} />
      <Button
        fullWidth
        sx={{ my: 0.5, justifyContent: "left" }}
        startIcon={<Undo sx={{ height: 20 }} />}
        disabled={isFetching}
        onClick={handleOnReset}
      >
        <Typography color={(theme) => theme.palette.common.black} fontWeight={500} fontSize={13}>
          Reset
        </Typography>
      </Button>
      <Divider />
      <Box display="flex" alignItems="center" justifyContent="flex-end" mt={2}>
        <LoadingButton color="primary" size="small" onClick={handleBack} variant="text" loading={isFetching}>
          Cancel
        </LoadingButton>
        <LoadingButton
          variant="contained"
          size="small"
          type="submit"
          sx={{ ml: 1, px: 2 }}
          data-testid="finish-button"
          form="assess-scoring-form"
          loading={isLoading}
        >
          Apply
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ScoringPanel;
