import React, { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";

import { useIsSystemAdminCheck } from "fond/hooks/useIsSystemAdminCheck";
import NoMatch from "fond/views/Error/NoMatch";
import { SuspenseSpinner } from "fond/widgets/SuspenseSpinner";

const AdminRouteLayout = lazy(() => import("fond-admin/AdminRouteLayout"));
const HomeView = lazy(() => import("fond-admin/views/HomeView"));
const UsersView = lazy(() => import("fond-admin/views/UsersView"));
const AccountsView = lazy(() => import("fond-admin/views/AccountsView"));
const BannersView = lazy(() => import("fond-admin/views/BannersView"));
const AuditView = lazy(() => import("fond-admin/views/AuditView"));

const AdminRouter = () => {
  const isSysAdmin = useIsSystemAdminCheck();
  if (!isSysAdmin) {
    return <NoMatch />;
  }

  return (
    <Routes>
      {/* routes are relative to /admin e.g. /admin/users should have the path users */}
      <Route Component={AdminRouteLayout}>
        <Route index Component={HomeView} />
        <Route path="users" Component={UsersView} />
        <Route path="accounts/*" Component={AccountsView} />
        <Route path="banners/*" Component={BannersView} />
        <Route path="audit" Component={AuditView} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};

const Main: React.FC = () => {
  return (
    <SuspenseSpinner>
      <AdminRouter />
      <Outlet />
    </SuspenseSpinner>
  );
};

export default Main;
