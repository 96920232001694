import { UserModule } from "./modules";
import { PermissionLevel } from "./permission";

export const UserRoles = {
  member: 0,
  admin: 1,
  owner: 2,
} as const;
export type UserRole = keyof typeof UserRoles;

export type UserBase = { ID: string; Email: string };

export type User = UserBase & {
  Rank: number | null;
};

/**
 * The current user's own user object.
 * This includes properties omitted from other user objects
 */
export type OwnUser = User & {
  Modules: UserModule[];
};

export type Invitation = {
  ID: string;
  AccountID: string;
  CreatedAt: string;
  ExpireTime: string;
  DeletedBy: string | null;
  Role: UserRole;
  InvitationStatus: string;
  Creator: UserBase;
  User: UserBase;
};

/**
 * When a user is invited to an account they do not have access to get the account name
 * so we include the account name in the invitation object.
 */
export type AccountInvitation = Invitation & {
  Account: AccountBase;
};

export type AccountBase = {
  ID: string;
  Name: string;
};

export type UserShareSuggestion = {
  Type: "UserShare";
  User: UserBase;
  PermissionLimit: null | {
    Reason: string;
    Level: PermissionLevel;
  };
};

export type UserMentionSuggestion = {
  Type: "UserMention";
  User: UserBase;
  Shared: boolean;
};

export enum UserPreferenceKey {
  UI_TOUR = "ui_tours",
  TOS_CHANGE = "terms_of_service_changes",
  GRID_STATE = "grid_state",
}

export enum UserPreferenceSubKey {
  /**
   * A tour for new users when they first view a project
   */
  PROJECT_INTRO = "intro_tour_active",
  /**
   * A tour for existing users as of FOND version 1.5 explaining
   * the change of layout (first release of the dynamic layout system)
   */
  DYNAMIC_LAYOUT = "dynamic_layouts_tour_active",
  /**
   * A tour for the introduction of style editor feature to users
   */
  STYLE_EDITOR = "style_editor_tour_active",
  /**
   * A notification of terms of service changes in May 2023.
   */
  MAY_2023_TOS_CHANGE = "2023-05_modal_active",
  /**
   * A tour for existing users introducing cost to serve feature
   * and major UI update on the auto design panel
   */
  COST_TO_SERVE_STACKED_NAVIGATION = "cost_to_serve_stack_navigation_active",
  /**
   * A tour for existing users introducing financial analytics
   */
  FINANCIAL_ANALYTICS_INTRO = "report_tour_active",
  /**
   * A tour containing link to the guide to generate report with an old project
   */
  FINANCIAL_ANALYTICS_OUTDATED_PROJECT = "report_outdated_project_tour_active",
}

export type UserPreference = {
  Key: UserPreferenceKey;
  Subkey: string;
  ID: string;
  Value: any;
  UserID: string | null;
};

/**
 * Customised planner features. This is used to apply customised architecture labels,
 * descriptions and rules to particular accounts.
 *
 * This can also be extended when we have more features, e.g:
 *    const FlexNapFeature = "FlexNap"
 *    const FooFeature = "Foo"
 *    type PlannerFeatures = typeof FlexNapFeature | typeof Foo
 */
export const FlexNapFeature = "FlexNap";
export type PlannerFeatures = typeof FlexNapFeature;
