import { createEntityAdapter, createSelector, EntityState } from "@reduxjs/toolkit";

import { ACCOUNT_CONTEXT } from "fond/constants";
import {
  Account,
  AccountBase,
  AccountModule,
  CreateAccountRequestBody,
  GetAccountUsersResponse,
  OkResponse,
  Store,
  Subscription,
  SubscriptionExpanded,
  SubscriptionRenewal,
  SubscriptionStatus,
  Usage,
  UserAccountAllocationResponse,
} from "fond/types";
import { PaymentMethod } from "fond/types/subscriptions";
import { getItem } from "fond/utils/localStorage";

import { apiSlice } from "./apiSlice";
import { selectUserAccountIdAtRandom, selectUserId } from "./userSlice";

export type GetAccountsResponse = {
  Items: Account[];
};

export const enum AccountLicenseStatus {
  VALID = "valid",
  EXPIRED = "expired",
}

export type GetAccountSubscriptionsResponse = {
  AccountSubscriptions: Subscription[];
};
export type GetExpandedAccountSubscriptionsResponse = {
  AccountSubscriptions: SubscriptionExpanded[];
};

export type GetAccountRenewStatusResponse = {
  Renewal: SubscriptionRenewal | null;
};

export const accountsAdapter = createEntityAdapter<Account>({
  selectId: (entity: Account): string => entity.ID,
});
const accountsInitialState = accountsAdapter.getInitialState();

export type GetAccountModulesResponse = {
  Modules: AccountModule[];
};

/**
 * Accounts API Slice
 */
export const accountSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createAccount: build.mutation<Account, CreateAccountRequestBody>({
      query: (accountDetails) => ({
        url: `/v2/accounts`,
        method: "POST",
        body: accountDetails,
      }),
      invalidatesTags: [{ type: "Account", id: "LIST" }],
    }),
    getAccounts: build.query<EntityState<Account>, void>({
      query: () => `/v2/accounts`,
      transformResponse: (response: GetAccountsResponse) => accountsAdapter.setAll(accountsInitialState, response.Items),
      providesTags: (result) =>
        result ? [...result.ids.map((id) => ({ type: "Account" as const, id })), { type: "Account", id: "LIST" }] : [{ type: "Account", id: "LIST" }],
    }),
    updateAccount: build.mutation<Account, AccountBase>({
      query: ({ ID, Name }) => ({
        url: `/v2/accounts/${ID}`,
        method: "PUT",
        body: { Name },
      }),
      invalidatesTags: (result) => [
        { type: "Account" as const, id: result?.ID ?? "LIST" },
        // We are required to invalidate the Folders and Projects as well to ensure the Account object
        // within these entities is also updated.
        { type: "Folder", id: "LIST" },
        { type: "Project", id: "LIST" },
      ],
    }),
    transferPermissions: build.mutation<OkResponse, { accountId: string; sourceUserId: string; targetUserId: string }>({
      query: ({ accountId, sourceUserId, targetUserId }) => ({
        url: `/v2/accounts/${accountId}/transfer-permissions`,
        method: "POST",
        body: {
          SourceUserID: sourceUserId,
          TargetUserID: targetUserId,
        },
      }),
      invalidatesTags: [{ type: "Permission", id: "LIST" }],
    }),
    getAccountSubscriptions: build.query<Subscription[], string>({
      query: (accountId: string) => `/v2/accounts/${accountId}/account-subscriptions?order=desc`,
      transformResponse: (response: GetAccountSubscriptionsResponse) => response?.AccountSubscriptions || [],
      providesTags: (result) =>
        result
          ? [...result.map((subscription) => ({ type: "Subscription" as const, id: subscription.ID })), { type: "Subscription", id: "LIST" }]
          : [{ type: "Subscription", id: "LIST" }],
    }),
    getAccountSubscriptionUsage: build.query<Usage, string>({
      query: (accountSubscriptionId: string) => `/v2/account-subscriptions/${accountSubscriptionId}/usage`,
    }),
    getAccountUsers: build.query<UserAccountAllocationResponse[], string>({
      query: (accountId: string) => `/v2/accounts/${accountId}/users`,
      transformResponse: (response: GetAccountUsersResponse) => {
        return response?.Allocations || [];
      },
      providesTags: (result) =>
        result
          ? [
              ...result.map((allocation) => ({ type: "UserAccountAllocation" as const, id: allocation.ID }), {
                type: "UserAccountAllocation",
                id: "LIST",
              }),
            ]
          : [{ type: "UserAccountAllocation", id: "LIST" }],
    }),
    getAccountPaymentMethod: build.query<PaymentMethod, string>({
      query: (accountId: string) => `/v2/accounts/${accountId}/payment-method`,
    }),
    getAccountRenewStatus: build.query<GetAccountRenewStatusResponse, string>({
      query: (accountId: string) => `/v2/accounts/${accountId}/renewal-status`,
      providesTags: (result) => (result ? [{ type: "RenewStatus", id: "ID" }] : []),
    }),
    getAccountModules: build.query<GetAccountModulesResponse, string>({
      query: (accountId: string) => `/v2/accounts/${accountId}/modules`,
    }),
  }),
});

/**
 * Endpoint Hooks
 */
export const {
  useCreateAccountMutation,
  useGetAccountsQuery,
  useUpdateAccountMutation,
  useGetAccountSubscriptionsQuery,
  useGetAccountSubscriptionUsageQuery,
  useGetAccountUsersQuery,
  useGetAccountPaymentMethodQuery,
  useGetAccountRenewStatusQuery,
  useGetAccountModulesQuery,
  useTransferPermissionsMutation,
} = accountSlice;

/**
 * Selectors
 */
const selectAccountsResult = accountSlice.endpoints.getAccounts.select();
const selectAccountsData = createSelector(selectAccountsResult, (accountsResult) => accountsResult.data);

export const { selectAll: selectAllAccounts, selectById: selectAccountById } = accountsAdapter.getSelectors(
  (state: Store) => selectAccountsData(state) ?? accountsInitialState
);

export const selectCurrentAccount = (state: Store): Account | null | undefined => {
  const userId = selectUserId(state);
  // TODO FND-618: selectUserAccountIdAtRandom shouldn't be necessary once users have the ability to select their account.
  const accountId = (userId && getItem(ACCOUNT_CONTEXT)?.[userId]) || selectUserAccountIdAtRandom(state);
  return accountId && selectAccountById(state, accountId);
};

// Return the currently active subscription or the first subscription.
export const selectCurrentSubscription = createSelector(
  [(state) => state, (state: Store, accountId: string) => accountId],
  (state: Store, accountId) => {
    const subscriptions = createSelector(
      accountSlice.endpoints.getAccountSubscriptions.select(accountId),
      (subscriptionsResult) => subscriptionsResult.data
    )(state);

    if (!subscriptions || subscriptions?.length === 0) return null;
    const activeSubscription = subscriptions.find((subscription) => subscription.Status === SubscriptionStatus.Active);
    return activeSubscription ?? subscriptions[0];
  }
);
