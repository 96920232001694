import React, { useMemo, useState } from "react";
import { GridOptions } from "@ag-grid-community/core";
import { Close, Edit } from "@mui/icons-material";
import { Alert, Box, Button, Divider, IconButton, Switch, Typography } from "@mui/material";

import { selectVersionConfig, useGetMultiProjectStatisticsQuery } from "fond/api";
import { assessConfigurationHasValues, getDefaultAssessConfiguration } from "fond/cityPlanner/assessConfigurationHelper";
import { getCurrentProjectView } from "fond/project";
import { toggleLayerVisibility } from "fond/project/redux";
import { MultiProject, MultiProjectArea, View } from "fond/types";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { AgGrid, useStackedNavigationContext } from "fond/widgets";

import { OverlayIds } from "../configuration";

import { rankingColumns } from "./rankingColumns";
import { ScoringValues } from "./ScoringValues";

interface AssessPanelProps {
  multiProject: MultiProject;
}

const AssessPanel: React.FC<AssessPanelProps> = ({ multiProject }: AssessPanelProps) => {
  const dispatch = useAppDispatch();
  const { open } = useStackedNavigationContext();
  const [showAlert, setShowAlert] = useState(true);
  const { isLoading, isFetching } = useGetMultiProjectStatisticsQuery(multiProject.ID);
  const { layers: layerView } = useAppSelector((state): View => getCurrentProjectView(state.project));
  const config = useAppSelector((state) => selectVersionConfig(state, multiProject.ID));

  const gridOptions: GridOptions<MultiProjectArea> = {
    animateRows: true,
    rowGroupPanelShow: "never",
    sideBar: false,
    pagination: false,
    suppressMovableColumns: true,
    domLayout: "normal",
    getRowId: (params) => params.data.ID,
  };

  const assessConfiguration = useMemo(() => {
    if (assessConfigurationHasValues(multiProject.AssessConfiguration)) {
      return multiProject.AssessConfiguration;
    }
    // If the configuration is empty, we need to set some default values to help users get started.
    return getDefaultAssessConfiguration();
  }, [multiProject.AssessConfiguration]);

  const handleOnClick = () => {
    open("scoring");
  };

  const handleOnOverlayClick = () => {
    dispatch(toggleLayerVisibility(multiProject.ID, OverlayIds.ROOT, config));
  };

  return (
    <>
      {showAlert && (
        <Alert
          severity="info"
          sx={{ mb: 2 }}
          action={
            <IconButton aria-label="close" size="small" onClick={() => setShowAlert(false)} color="inherit">
              <Close fontSize="inherit" />
            </IconButton>
          }
          variant="compact"
        >
          The assessment step helps you quickly evaluate large markets.
        </Alert>
      )}
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 500 }}>
          Overlays
        </Typography>
        <Switch size="small" checked={layerView[OverlayIds.ROOT]} onClick={handleOnOverlayClick} />
      </Box>
      <Typography component="div" variant="caption" sx={{ mb: 2 }}>
        Enable overlays to identify which datasets provide the most valuable insights into your subareas.
      </Typography>
      <Divider sx={{ my: 2 }} />
      <Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 500 }}>
            Scoring inputs
          </Typography>
          <Button size="small" onClick={handleOnClick}>
            <Edit sx={{ width: 16, height: 16, mr: 0.5 }} />
            Edit
          </Button>
        </Box>
        <Typography component="div" variant="caption" sx={{ mb: 2 }}>
          The scoring system helps to quickly assess which subareas to proceed with in the next steps.
        </Typography>
        <ScoringValues factor="cost" title="Cost factors" config={multiProject.AssessConfiguration} />
        <ScoringValues factor="revenue" title="Revenue factors" config={multiProject.AssessConfiguration} />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h6" sx={{ fontSize: 14, fontWeight: 500 }}>
          Score board
        </Typography>
        <Box sx={{ "& .ag-center-cols-viewport": { minHeight: "unset !important" } }}>
          <AgGrid
            columnDefs={rankingColumns}
            rowData={multiProject.Areas}
            containerProps={{
              height: 300,
            }}
            size="compact"
            variant="outlined"
            gridOptions={gridOptions}
            loading={isLoading || isFetching}
          />
        </Box>
      </Box>
    </>
  );
};

export default AssessPanel;
