import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { ArrowBackIosNew, ArrowForwardIos, FilePresent } from "@mui/icons-material";
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography, Zoom } from "@mui/material";
import dayjs from "dayjs";
import Swiper from "swiper";
import { Keyboard, Pagination, Virtual } from "swiper/modules";
import { Swiper as SwiperComponent, SwiperSlide } from "swiper/react";

import "swiper/css/navigation";

import { selectUsername } from "fond/api";
import { Attachment } from "fond/types";
import { formatBytes } from "fond/utils";

import MimeTypeIcon from "../MimeTypeIcon";

// Import Swiper styles
import "swiper/css";
import { FilesViewerModal, ModalAside, NextSlideButton, PrevSlideButton } from "./FileViewerModal.styles";

interface IProps {
  open: boolean;
  onClose(): void;
  files: Attachment[];
  initialSlide: number;
}

const FilesSliderModal: React.FC<IProps> = ({ open, onClose, files, initialSlide }) => {
  const swiperRef = useRef<Swiper | null>(null);
  const currentUsername = useSelector(selectUsername);
  const [isZoomOpen, setIsZoomOpen] = useState(open);
  const [isBeginning, setIsBeginning] = useState(initialSlide === 0);
  const [isEnd, setIsEnd] = useState(initialSlide === files.length - 1);

  useEffect(() => {
    setIsBeginning(initialSlide === 0);
    setIsEnd(initialSlide === files.length - 1);
  }, [initialSlide, files.length]);

  useEffect(() => {
    setIsZoomOpen(open);
  }, [open]);

  const onCloseModal = () => {
    // zoom out before closing the modal
    setIsZoomOpen(false);
  };

  const onSwiperInit = (swiper: Swiper) => {
    swiperRef.current = swiper;
    swiper.on("activeIndexChange", () => {
      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    });
  };

  return (
    <FilesViewerModal role="dialog" id="files-viewer-slider-modal" open={open} onClose={onCloseModal}>
      <Zoom in={isZoomOpen} onExited={onClose}>
        <Box height="100%" display="flex" alignItems="center" justifyContent="center">
          <Box width="90vw" height="45vw" display="flex" flexDirection="column" sx={{ backgroundColor: "white" }}>
            <DialogTitle />
            <DialogContent sx={{ flex: 1 }}>
              <SwiperComponent
                modules={[Virtual, Keyboard, Pagination]}
                pagination={{
                  type: "fraction",
                }}
                keyboard={{
                  enabled: true,
                  onlyInViewport: true,
                }}
                virtual
                style={{ height: "100%" }}
                initialSlide={initialSlide}
                onSwiper={onSwiperInit}
              >
                {files.map((file, index, slides) => (
                  <SwiperSlide key={file.ID} virtualIndex={index}>
                    <Box display="flex" height="100%">
                      <Box flex={1} display="flex" position="relative" alignItems="center" justifyContent="center">
                        {file.MimeType?.split("/")[0] === "image" ? (
                          <img
                            src={file.Urls?.View}
                            alt={`${file.Name}.${file.Extension}`}
                            loading="lazy"
                            style={{ height: "100%", width: "100%", objectFit: "contain" }}
                          />
                        ) : (
                          <Box
                            width={830}
                            height={556}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            sx={(theme) => ({ background: theme.palette.biarri.secondary.whiteSmoke })}
                            fontSize={60}
                          >
                            <MimeTypeIcon
                              mimeType={file.MimeType}
                              color="action"
                              fontSize="inherit"
                              defaultIcon={<FilePresent color="action" fontSize="inherit" />}
                            />
                          </Box>
                        )}
                      </Box>
                      <ModalAside component="aside">
                        <Typography variant="h2">
                          {file.Name}.{file.Extension}
                        </Typography>
                        {file.Description && <Typography className="description">{file.Description}</Typography>}

                        <Box className="attachment-meta">
                          <Typography className="attachment-meta-heading">Information</Typography>
                          <dl>
                            <dt>Size</dt>
                            <dd>{formatBytes(file.Size)}</dd>
                            <dt>Uploaded by</dt>
                            <dd>{currentUsername === file.UploadedBy ? "me" : file.UploadedBy}</dd>
                            <dt>Upload date</dt>
                            <dd>{dayjs(file.UploadedAt).format("YYYY-MM-DD")}</dd>
                          </dl>
                        </Box>
                      </ModalAside>
                    </Box>
                  </SwiperSlide>
                ))}
                <PrevSlideButton
                  aria-label="Previous item"
                  size="large"
                  color="primary"
                  disableRipple
                  onClick={() => swiperRef.current!.slidePrev()}
                  disabled={isBeginning}
                >
                  <ArrowBackIosNew fontSize="inherit" />
                </PrevSlideButton>
                <NextSlideButton
                  aria-label="Next item"
                  size="large"
                  color="primary"
                  disableRipple
                  onClick={() => swiperRef.current!.slideNext()}
                  disabled={isEnd}
                >
                  <ArrowForwardIos fontSize="inherit" />
                </NextSlideButton>
              </SwiperComponent>
            </DialogContent>
            <DialogActions>
              <Button size="small" variant="contained" onClick={onCloseModal}>
                Close
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </Zoom>
    </FilesViewerModal>
  );
};

export default FilesSliderModal;
