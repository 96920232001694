import React, { useMemo } from "react";
import { CheckCircle, FilePresent, RadioButtonUnchecked } from "@mui/icons-material";
import { Box, Checkbox, Divider } from "@mui/material";

import { Attachment } from "fond/types";
import { formatBytes } from "fond/utils";
import { scaleElement } from "fond/utils/dom";

import MimeTypeIcon from "../MimeTypeIcon";

import { Sort } from "./types";

import { GalleryImageListItem, ImageList, ImageListItemBar, ImagePlaceholder, ImageWrapper, SelectFileLabel } from "./GalleryView.styles";

interface IProps {
  files: Attachment[];
  selected: Attachment[];
  onSelectionChange(files: Attachment[]): void;
  sort: Sort | null;
  handleZoomOpen(index: number): void;
}

const GalleryView: React.FC<IProps> = ({ files, selected, onSelectionChange, sort, handleZoomOpen }) => {
  const selectedFileIDs = useMemo(() => selected.map((file) => file.ID), [selected]);
  const sortedFiles = useMemo(() => {
    if (!sort) return files;
    let sorted = files.sort((prev, next) => {
      if (typeof prev[sort.colId] === "number") {
        return (prev[sort.colId] as number) - (next[sort.colId] as number);
      } else {
        return (prev[sort.colId] as string).localeCompare(next[sort.colId] as string);
      }
    });
    if (sort.sort === "desc") return sorted.toReversed();
    return sorted;
  }, [files, sort]);

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, file: Attachment) => {
    const el = document.getElementById(`image-${file.ID}`);
    if (event.target.checked) {
      onSelectionChange([...selected, file]);
      scaleElement(el);
    } else {
      scaleElement(el, 0);
      const selectedClone = [...selected];
      const index = selected.findIndex((selectedFile) => selectedFile.ID === file.ID);
      if (index === -1) return;
      selectedClone.splice(index, 1);
      onSelectionChange(selectedClone);
    }
  };

  return (
    <>
      <Divider />

      <ImageList variant="standard" cols={2} gap={8}>
        {sortedFiles.map((file, index) => {
          const isSelected = selectedFileIDs.includes(file.ID);
          const fileDescriptionId = `file-description-${file.ID}`;

          return (
            <GalleryImageListItem key={file.ID}>
              <Box height="100%" maxWidth="100%" display="flex" alignItems="end" justifyContent="center">
                <ImageWrapper isSelected={isSelected} data-testid="image-wrapper" onClick={(e) => handleZoomOpen(index)}>
                  {file.MimeType.split("/")[0] === "image" ? (
                    <img src={file.Urls?.View} id={`image-${file.ID}`} alt="" loading="lazy" />
                  ) : (
                    <ImagePlaceholder className="non-image-placeholder" id={`image-${file.ID}`} data-testid="image-placeholder">
                      <MimeTypeIcon
                        mimeType={file.MimeType}
                        color="inherit"
                        fontSize="large"
                        defaultIcon={<FilePresent color="action" fontSize="large" />}
                      />
                    </ImagePlaceholder>
                  )}
                  <SelectFileLabel
                    label={`Select ${file.Name}.${file.Extension}`}
                    onClick={(event) => event.stopPropagation() /* Prevents the thumbnail from being clicked */}
                    control={
                      <Checkbox
                        id={file.ID}
                        checked={isSelected}
                        onChange={(event) => onCheckboxChange(event, file)}
                        size="small"
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<CheckCircle sx={{ backgroundColor: "#ffffff", borderRadius: "100%" }} />}
                      />
                    }
                  />
                </ImageWrapper>
              </Box>
              <ImageListItemBar id={fileDescriptionId} title={`${file.Name}.${file.Extension}`} subtitle={formatBytes(file.Size)} position="below" />
            </GalleryImageListItem>
          );
        })}
      </ImageList>
    </>
  );
};

export default GalleryView;
