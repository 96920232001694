import React, { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Alert, Box, Button, Typography } from "@mui/material";
import _ from "lodash";
import { useSnackbar } from "notistack";

import { LoadingButton } from "ui";

import { selectFoldersForAddingProject, useCopyProjectMutation } from "fond/api";
import { Autocomplete } from "fond/form/fields";
import mixpanel from "fond/mixpanel";
import { Folder, HomeFolder, ProjectPreview, Store } from "fond/types";
import { required } from "fond/utils/validation";
import { Modal } from "fond/widgets";
import SupportLink from "fond/widgets/SupportLink";

interface IFormData {
  FolderId: string;
}
interface IProps {
  project: ProjectPreview;
  closeModalCallback(): void;
}

const CopyProjectDialog: React.FC<IProps> = ({ project, closeModalCallback }: IProps) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const folders = useSelector((state: Store) => selectFoldersForAddingProject(state, project));
  const [saving, setSaving] = useState(false);

  const KnowledgeBaseLink = <a href="https://fondhelp.biarrinetworks.com/how-to-control-fiber-allocation-in-fond">this knowledge base article</a>;
  const [copyProject] = useCopyProjectMutation();

  // folderOptions adds home route that allows user to move a project back to home screen
  // It sets the ID to "home" rather than null because TS will only allow for a string
  const folderOptions = useMemo(() => {
    return [
      {
        ID: "home",
        Name: "Home",
        Path: [],
      },
      ..._.sortBy(folders, ["Path", "Name"]),
    ];
  }, [folders]);

  const handleNavigateToCopyFolder = (folderId: string | null) => {
    const redirectPath = folderId ? `/folders/${folderId}` : "/";
    navigate(redirectPath);
  };

  /**
   * Handles the copying of the project.
   */
  const handleOnCopy = async (values: IFormData) => {
    const projectId = project.ID;
    const folderId = values.FolderId === "home" ? null : values.FolderId;
    setSaving(true);

    try {
      await copyProject({ projectId, folderId }).unwrap();
      mixpanel.track("Copied project", { projectId });
      enqueueSnackbar(`Created project 'Copy of ${project.ProjectName}'.`, {
        action: (
          <Button variant="text" color="primary" onClick={() => handleNavigateToCopyFolder(folderId)}>
            View
          </Button>
        ),
      });
    } catch {
      enqueueSnackbar("Project copy failed. Please try again...");
    } finally {
      setSaving(false);
      closeModalCallback();
    }
  };

  return (
    <Modal
      open
      data-testid="copy-project-modal"
      header="Copy project"
      content={
        project?.UsesBlueSteelV11 ? (
          <>
            <Typography variant="body2" style={{ marginBottom: 12 }}>
              Since this project was made, there have been improvements to the way the amount of fiber at address points is specified.
            </Typography>
            <Typography variant="body2" style={{ marginBottom: 12 }}>
              The new copy of this project will be affected by these changes. This may cause changes to the design when regenerated.
            </Typography>
            <Typography variant="body2" style={{ marginBottom: 12 }}>
              Previously the amount of fiber was set using the "Demand" value on each address point. This value is no longer used. It is now set using
              the "Type" and "Number of fibers" value for each address point. More information can be found in&bsp;
              {KnowledgeBaseLink}
            </Typography>
            <Typography variant="body2" style={{ marginBottom: 12 }}>
              Please let us know if you have any questions.
            </Typography>
            <SupportLink />
          </>
        ) : (
          <>
            <Typography variant="body2" sx={{ mb: 4 }}>
              This action will copy the project settings and design data, but not comments and attachments.
            </Typography>
            <Form<IFormData>
              onSubmit={handleOnCopy}
              initialValues={{ FolderId: project.FolderID || "home" }}
              render={({ handleSubmit }) => (
                <form id="copy-project-form" onSubmit={handleSubmit}>
                  <Box>
                    <Autocomplete
                      name="FolderId"
                      required
                      label="Where should we make a copy of your project?"
                      fullWidth
                      options={folderOptions}
                      getOptionLabel={(option) => option.Name}
                      getOptionValue={(option) => option?.ID || "home"}
                      renderOption={(props, option: Folder | HomeFolder) => (
                        <li {...props} key={option.ID}>
                          <Box data-testid="copy-project-item" display="flex" flexDirection="column">
                            <Typography>{option.Name}</Typography>
                            <Typography variant="caption">
                              {`${project.Account.Name} > Home ${option.Path.length ? " > " : ""} ${option.Path.join(" > ")}`}
                            </Typography>
                          </Box>
                        </li>
                      )}
                      filterOptions={(options, state) =>
                        options.filter((option) => option.Name.toLowerCase().includes(state.inputValue.toLowerCase()))
                      }
                      validate={required}
                    />
                  </Box>
                </form>
              )}
            />
            <Box mb={2}>
              <Alert severity="warning">
                Copying a project to a different location has the potential to change who can access & edit the project.
              </Alert>
            </Box>
          </>
        )
      }
      actions={
        <>
          <Button color="primary" data-testid="cancel-project-copy" onClick={closeModalCallback} sx={{ marginRight: 1 }}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            form="copy-project-form"
            loading={saving}
            data-testid="confirm-project-copy"
          >
            Copy
          </LoadingButton>
        </>
      }
    />
  );
};

export default CopyProjectDialog;
