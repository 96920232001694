import { useSelector } from "react-redux";

import { selectUserModules } from "fond/api";
import { getImpersonatedUser } from "fond/impersonate";

/**
 * Check whether the current user is a system admin.
 * - They must have access to the `fond_admin` module.
 * - They must not be impersonating another user.
 */
export const useIsSystemAdminCheck = (): boolean => {
  const isFondAdmin = useSelector(selectUserModules).some((module) => module.Name === "fond_admin");

  const impersonate = getImpersonatedUser();
  // disallow system admin access when impersonating
  if (impersonate) {
    return false;
  }
  return isFondAdmin;
};
