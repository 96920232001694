import React, { useEffect, useState } from "react";
import { shallowEqual } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { isEqual, mapValues } from "lodash";
import { useSnackbar } from "notistack";

import { selectLayerByVersionAndLayerKey, selectLayerPropertiesSchema, useGetVersionQuery } from "fond/api";
import { AttachmentModal } from "fond/attachments";
import AddQuickFeatureAttachment from "fond/attachments/AddQuickFeatureAttachment";
import { LayerIds } from "fond/layers";
import { TabHeader } from "fond/layout";
import { trafficLightData } from "fond/map/Field/PreferenceFieldButton";
import mixpanel from "fond/mixpanel";
import { getAttachments } from "fond/redux/attachments";
import { getFeature, getOne } from "fond/redux/features";
import { Attribute } from "fond/types";
import { convertMetersToFeet, roundLength } from "fond/utils";
import { useAppDispatch, useAppSelector } from "fond/utils/hooks";
import { Actions, permissionCheck } from "fond/utils/permissions";
import { projectUsesVectorTiles } from "fond/utils/project";

import { getArchitectureAddressTypes } from "../addressTypes";
import AddQuickFeatureComment from "../comments/AddQuickComment";
import { isSolveActive, updateFeatureProperties } from "../redux";
import { getCurrentProject } from "..";

import PropertiesPanelContent from "./PropertiesPanelContent";

/**
 * `fields` is a mapping of field names to field descriptors. A field
 * descriptor has:
 *
 * A `validate` function -- returns true if the field value is valid, else false.
 *
 * Note that the field value is anything that can be represented in the
 * corresponding widget. For example, if the field is supposed to be a number
 * but is represented by a text field on the screen, the field value here will
 * be a string, because it is still possible for the user to type non-numeric
 * characters in the input. Even if the widget is a strict `<input type="number">`
 * the user will still be able to things like "0000", "." or "e".
 *
 * A `sanitize` function -- convert the value from the widget to the value that
 * should be sent to the server. This function may assume `validate` has
 * already returned true for the value passed in.
 *
 * Optionally, a `label` property. If provided, it's used as the field value. If absent,
 * the field key is used.
 */
const fields: { [key: string]: { label?: string; validate: (value: string) => boolean; sanitize: (value: string) => number | string } } = {
  AddressType: {
    validate: (value: string) => true,
    sanitize: (value: string) => value,
  },
  CostFactor: {
    label: "Preference",
    validate: (value: string) => {
      return value !== "" && Number.isFinite(Number(value));
    },
    sanitize: parseFloat,
  },
};

const PropertiesPanel: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useAppDispatch();
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [addNewComment, setAddNewComment] = useState(false);
  const [propertyHasChanged, setPropertyHasChanged] = useState(false);
  const project = useAppSelector((state) => getCurrentProject(state.project));
  const versionId = useAppSelector((state) => state.project.versionId);
  const { data: version } = useGetVersionQuery(versionId, { skip: !versionId });
  const { selectedFeature } = useAppSelector((state) => state.project);
  const { layerId } = selectedFeature;
  const isFetching = useAppSelector((state) => state.features.isFetching);
  const solveActive = useAppSelector((state) => isSolveActive(state));
  const layerConfig = useAppSelector((state) => selectLayerByVersionAndLayerKey(state, { versionId, layerId }));
  const layerPropertiesSchemas = useAppSelector((state) => selectLayerPropertiesSchema(state, versionId));
  const architectureAddressTypes = version?.Architecture
    ? getArchitectureAddressTypes({ architecture: version.Architecture, excludeIgnore: true })
    : null;

  const lengthFields =
    layerConfig?.Attributes.filter((attribute) => attribute.SourceSystemOfMeasurement !== null).map((attribute) => attribute.Name) || [];
  const title = layerConfig?.Configuration.Label;

  const editableProperties =
    {
      [LayerIds.inAddress]: ["AddressType"],
      [LayerIds.inStreet]: ["CostFactor"],
      [LayerIds.inSpan]: ["CostFactor"],
    }[layerId] || [];

  const initialFeatureProperties: Record<string, string | number> = useAppSelector((state) => {
    // TODO: Once all projects use vector tiles properties should always come from the getOne redux selector
    let properties = backendToUI({
      ...(getOne(state)(state.project.selectedFeature.featureProperties.id)?.properties || state.project.selectedFeature.featureProperties),
    });

    // Handle length conversions
    lengthFields.forEach((field) => {
      if (Object.keys(properties).includes(field)) {
        properties = {
          ...properties,
          [field]: project.SystemOfMeasurement === "imperial" ? roundLength(convertMetersToFeet(properties[field])) : roundLength(properties[field]),
        };
      }
    });

    // Set default Cost Factor
    if (editableProperties.includes("CostFactor") && properties.CostFactor == null) {
      properties = { ...properties, CostFactor: trafficLightData.neutral.cost };
    }

    return properties;
  }, shallowEqual);

  /**
   * We keep a copy of the initialFeatureProperties in state to support mutating the data
   * e.g. the user edits the properties (e.g. Address Preference)
   */
  const [featureProperties, setFeatureProperties] = useState(initialFeatureProperties);

  useEffect(() => {
    // If the initial features from the redux store differ from state
    // update state.  For example the properties have finished loading
    // from the server.
    if (!isEqual(initialFeatureProperties, featureProperties) && !propertyHasChanged) {
      setFeatureProperties(initialFeatureProperties);
    }
  }, [initialFeatureProperties]);

  /**
   * Monitor the popup for changes to the feature id it is referencing.
   * If the feature changes we need to request new information.
   */
  useEffect(() => {
    /**
     * TODO Once all projects use vector tiles always request the information unless already loaded
     * If using vector tiles we need to request the data if it does not already exist
     *
     * Note: If the properties between the FeatureProperties & LayerProperties scheme differ we request
     * from the server.
     */
    if (projectUsesVectorTiles(project) && layerPropertiesSchemas) {
      if (!isFetching && !equalProperties(layerPropertiesSchemas[layerId], initialFeatureProperties)) {
        dispatch(getFeature(selectedFeature.feature.id as string));
      }
    }
  }, [selectedFeature.feature.id]);

  useEffect(() => {
    const loadAttachments = () => {
      // Feature IDs can be stored in different places depending on the source
      const featureId = (selectedFeature.featureId as string) || selectedFeature.featureProperties.id;
      dispatch(getAttachments(project.ID, featureId)).catch(() => {
        enqueueSnackbar("Attachments failed to load.", {
          action: (
            <Button color="primary" onClick={loadAttachments}>
              Retry
            </Button>
          ),
        });
      });
    };
    loadAttachments();
  }, [dispatch, enqueueSnackbar, project.ID, selectedFeature.featureId]);

  /**
   * Validates the editable properties for valid values.
   */
  const isValid = () => {
    return editableProperties.every((key) => {
      return fields[key].validate(String(featureProperties[key]));
    });
  };

  /**
   * Updates the Field Value within the feature properties
   */
  const setFieldValues = (updates: { [key: string]: string | number }) => {
    mixpanel.track("Edited feature value", updates);
    setPropertyHasChanged(true);

    const updatedFeatureProperties = { ...featureProperties, ...updates };

    setFeatureProperties(updatedFeatureProperties);

    if (canSave()) {
      const properties = uiToBackend(editableProperties, updatedFeatureProperties);
      dispatch(updateFeatureProperties(layerId, selectedFeature.featureId, properties));
    } else {
      mixpanel.track("Unable to save feature");
      enqueueSnackbar("Unable to save new update");
    }
  };

  /**
   * Indicates if the current features can be saved
   */
  const canSave = () => isValid() && !solveActive;

  const hideQuickComment = () => setAddNewComment(false);
  const layerPropertiesSchema = layerPropertiesSchemas[layerId];

  const mainContentProps = {
    project,
    layerPropertiesSchema,
    addNewComment,
    hideQuickComment,
    editableProperties,
    layerId,
    version,
    featureProperties,
    architectureAddressTypes,
    setFieldValues,
    lengthFields,
    isFetching,
  };

  return (
    <Box height="100%" display="flex" flexDirection="column" overflow="hidden" data-testid="content-properties-panel">
      <TabHeader
        rightAdornments={
          <>
            {permissionCheck(project.Permission.Level, Actions.PROJECT_UPLOAD_ATTACHMENT) && (
              <AddQuickFeatureAttachment addAttachment={() => setShowAttachmentModal(true)} />
            )}
            <AddQuickFeatureComment addComment={() => setAddNewComment(true)} />
          </>
        }
      />
      <Typography fontWeight={500} sx={{ mt: 1, ml: 1.5 }} data-testid="content-properties-title">
        {title}
      </Typography>
      <PropertiesPanelContent key={selectedFeature.featureId} {...mainContentProps} />
      {showAttachmentModal && (
        <AttachmentModal onClose={() => setShowAttachmentModal(false)} isOpen={showAttachmentModal} attachmentEntityType="Feature" />
      )}
    </Box>
  );
};

export default PropertiesPanel;

export const backendToUI = (featureProperties: any): { [key: string]: any } => {
  let modifiedFeatureProps = featureProperties;

  // If "null" is somehow saved in the backend, this ensures that it is converted to `null`
  if (Object.values(modifiedFeatureProps).includes("null")) {
    modifiedFeatureProps = mapValues(modifiedFeatureProps, (value) => (value === "null" ? null : value));
  }
  return modifiedFeatureProps;
};

export const uiToBackend = (editableProperties: any, featureProperties: any) => {
  let properties = { ...featureProperties };

  for (let prop of editableProperties) {
    properties[prop] = fields[prop].sanitize(featureProperties[prop]);
  }

  const { Type, NumFibers } = featureProperties;
  if (Type !== "T2_EXP_DEMAND") {
    properties.SubTypes = null;
  }
  properties.NumFibers = parseInt(NumFibers, 10); // NumFibers must be an integer
  return properties;
};

/**
 * Determines if the properties within the layer properties schema are
 * the same as the feature properties being displayed (ignoring order)
 */
const equalProperties = (
  layerPropertiesSchema: Attribute[],
  featureProperties: {
    [name: string]: any;
  }
) => {
  const schemaPropertyNames = layerPropertiesSchema?.map((property) => property.Name).sort();
  const featurePropertyNames = Object.keys(featureProperties).sort();

  return isEqual(schemaPropertyNames, featurePropertyNames);
};
