import { AccountModuleName } from "./modules";

export enum SubscriptionStatus {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  NotStarted = "NOT_STARTED",
  Unpaid = "UNPAID",
  Cancelled = "CANCELLED",
}

export interface PlannerSubscription {
  Quota: number;
  QuotaUsage: number;
  TierID: string;
}

export type PlannerSubscriptionExpanded = PlannerSubscription & {
  Extensions: AccountModuleName[];
  Tier: string;
};

export interface Subscription {
  ID: string;
  AccountID: string;
  StartTime: string;
  EndTime: string;
  Status: SubscriptionStatus;
  Planner: PlannerSubscription | null;
  LicenseCount: number;
}

export type SubscriptionExpanded = Subscription & {
  CancelTime: string | null;
  PaidUntilTime: string;
  StripeID: string;
  Planner: PlannerSubscriptionExpanded | null;
};

export interface Bonus {
  Quota: number;
  Percentage: number;
  AvailableUntilTime: string;
}

export interface SubscriptionRenewal {
  BaseSubscription: Subscription;
  Bonus: Bonus;
}

export type UsageItem = {
  Email: string;
  Usage: number;
};

export type DailyUsageItem = {
  Date: string;
  DailyUsageByAccount: number;
  DailyUsageByUser: UsageItem[];
};

export type Usage = {
  StartDate: string;
  EndDate: string;
  TotalUsageByAccount: number;
  TotalUsageByUser: UsageItem[];
  DailyUsage: DailyUsageItem[];
};
