import { Box, dialogContentClasses, IconButton, Modal, styled } from "@mui/material";

export const FilesViewerModal = styled(Modal)`
  & .${dialogContentClasses.root} {
    padding: 0;
  }

  .swiper-slide {
    box-sizing: border-box;
    padding: ${({ theme }) => theme.spacing(2, 5)};
  }

  .swiper-pagination {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background-color: white;
    padding: 10px 20px;
    border-radius: 4px;
    z-index: 10;
    border: 1px solid ${({ theme }) => theme.palette.divider};
  }
`;

export const PrevSlideButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 2.5rem;
  z-index: 10;
  background-color: white;
  box-shadow: ${({ theme }) => theme.shadows[3]};
  transition: all ${({ theme }) => theme.transitions.duration.shorter}ms;

  svg {
    scale: 1;
    transition: scale ${({ theme }) => theme.transitions.duration.shorter}ms;
  }

  &[disabled] {
    background-color: ${({ theme }) => theme.palette.biarri.secondary.whiteSmoke};
    box-shadow: ${({ theme }) => theme.shadows[1]};

    svg {
      scale: 0.9;
    }
  }
`;

export const NextSlideButton = styled(PrevSlideButton)`
  left: unset;
  right: 10px;
  padding: 12px; // reset to default button padding
`;

export const ModalAside = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 380px;
  padding: ${({ theme }) => theme.spacing(1, 5, 5)};
  border-left: 1px solid ${({ theme }) => theme.palette.divider};
  margin-left: ${({ theme }) => theme.spacing(5)};

  h2,
  .description {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }

  .description {
    color: ${({ theme }) => theme.palette.text.secondary};
  }

  .attachment-meta {
    margin-top: ${({ theme }) => theme.spacing(2)};

    .attachment-meta-heading {
      padding-bottom: ${({ theme }) => theme.spacing(1)};
      margin-bottom: ${({ theme }) => theme.spacing(1)};
      border-bottom: 1px solid ${({ theme }) => theme.palette.divider};
    }

    dt {
      font-size: ${({ theme }) => theme.typography.body3.fontSize}px;
      color: ${({ theme }) => theme.palette.divider}0;
      margin-bottom: ${({ theme }) => theme.palette.divider}1;
    }

    dd {
      font-size: ${({ theme }) => theme.palette.divider}2px;
      margin: 0;
      margin-bottom: ${({ theme }) => theme.palette.divider}3;
    }
  }
`;
