import { useField, useFormState } from "react-final-form";
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from "@mui/icons-material";
import { ListItemIcon, ListItemText } from "@mui/material";
import { get } from "lodash";

import { ScoringInputConfig } from "fond/cityPlanner/assessConfigurationHelper";

import { StyledMenuItem } from "./ScoringItemsMenuItem.styles";

type ScoringItemsMenuItemProps = {
  item: ScoringInputConfig;
  onSelect: (itemId: string) => void;
};

export const ScoringItemsMenuItem: React.FC<ScoringItemsMenuItemProps> = ({ item, onSelect }: ScoringItemsMenuItemProps) => {
  useField(item.id); // register the field for updates
  const { values } = useFormState(); // use the form state rather than field value because it works more reliably in tests
  const value = get(values, item.id) ?? "";
  const labelId = `${item.id.replace(/\W/g, "-")}-label`;

  return (
    <StyledMenuItem key={item.id} onClick={() => onSelect(item.id)} value={item.id} dense>
      <ListItemIcon role="menuitemcheckbox" aria-checked={value !== ""} aria-labelledby={labelId}>
        {value !== "" ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlank />}
      </ListItemIcon>
      <ListItemText id={labelId} primary={item.title} secondary={item.description} />
    </StyledMenuItem>
  );
};
