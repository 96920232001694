import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { ErrorBoundary } from "@sentry/react";

import { selectAllBanners, useGetBannersQuery } from "fond/api/bannerSlice";
import { MINUTE } from "fond/constants";

const BANNER_POLLING_INTERVAL = 5 * MINUTE;

const NotificationBanner = lazy(() => import("./NotificationBanner"));

export const NotificationBannerSlot: React.FC = () => {
  const { isLoading } = useGetBannersQuery(undefined, {
    pollingInterval: BANNER_POLLING_INTERVAL,
  });
  const banners = useSelector(selectAllBanners);

  // TODO: FND-3074 - Filter banners by whether they have been dismissed based on LocalStorage state

  if (isLoading || !banners?.length) {
    return null;
  }

  return (
    <ErrorBoundary>
      <div className="notification-banner-slot">
        <Suspense fallback={null}>
          {banners.map((banner) => (
            <NotificationBanner key={banner.ID} id={banner.ID} banner={banner} />
          ))}
        </Suspense>
      </div>
    </ErrorBoundary>
  );
};
