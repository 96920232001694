import React from "react";
import { useSelector } from "react-redux";
import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";

import { selectCurrentAllocation, selectFolderById, useGetAccountSubscriptionsQuery } from "fond/api";
import mixpanel from "fond/mixpanel";
import ReportList from "fond/project/panels/ReportPanel/ReportList";
import { MultiProject, MultiReport, Store, SubscriptionStatus } from "fond/types";
import { useAppSelector } from "fond/utils/hooks";
import { Actions, licenseCheck, permissionCheck } from "fond/utils/permissions";
import { LinearProgressWithLabel } from "fond/widgets";

import { ReportConfigurationModal } from "./ReportConfigurationModal";

interface IProps {
  multiProject: MultiProject;
  multiReports?: MultiReport[];
  progress?: number | null;
}

const ReportPanel: React.FC<IProps> = ({ multiProject, multiReports, progress = null }: IProps) => {
  const currentAllocation = useAppSelector(selectCurrentAllocation);
  const currentFolder = useSelector((state: Store) => (multiProject.Folder.ID ? selectFolderById(state, multiProject.Folder.ID) : undefined));
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAllocation?.Account.ID ?? skipToken);
  const hasActiveSubscription = subscriptions?.some((subscription) => subscription.Status === SubscriptionStatus.Active);
  const hasValidLicense = licenseCheck(currentAllocation, Actions.CITY_PLANNER_REPORT_ADD) && hasActiveSubscription;
  const hasPermission = !!currentFolder && permissionCheck(currentFolder.Permission.Level, Actions.CITY_PLANNER_REPORT_ADD);
  const hasSubAreaProject = multiProject.Areas.some((area) => area.Project?.ID);
  const canCreateMultiReport = hasSubAreaProject && hasValidLicense && hasPermission;
  const [reportConfigurationModalOpen, setReportConfigurationModalOpen] = React.useState(false);
  const isCreatingProjects = progress !== null && progress !== 1;
  const handleCreateReportClick = () => {
    mixpanel.track("Create multi report", "MultiReport", "MultiReport panel");
    setReportConfigurationModalOpen(true);
  };

  const onCloseReportConfiguration = () => {
    setReportConfigurationModalOpen(false);
  };

  return (
    <>
      {!hasSubAreaProject && (
        <Box mb={1}>
          <Alert severity="warning">
            <Typography variant="body3">Create at least one subarea project before generating a new report</Typography>
          </Alert>
        </Box>
      )}
      {isCreatingProjects ? (
        <Alert severity="info" variant="compact">
          <AlertTitle>Projects are being created</AlertTitle>
          <LinearProgressWithLabel value={progress * 100} variant="determinate" />
        </Alert>
      ) : (
        hasSubAreaProject && (
          <Alert severity="success">
            <AlertTitle>Projects ready for reporting</AlertTitle>
            <Typography fontSize={12}>You may configure a report now.</Typography>
          </Alert>
        )
      )}

      <Typography my={1} fontSize={14} variant="h6">
        {multiReports?.length === 0 ? "Report not generated" : "Generate report"}
      </Typography>
      <Typography variant="body3" component="p" mb={2}>
        Generate a business case analysis based on your subareas.
      </Typography>

      <Button onClick={handleCreateReportClick} disabled={!canCreateMultiReport || isCreatingProjects} variant="contained" fullWidth>
        {isCreatingProjects ? "Waiting on projects..." : "Create report"}
      </Button>

      {multiReports && <ReportList reports={multiReports} />}
      {reportConfigurationModalOpen && (
        <ReportConfigurationModal project={multiProject} open={reportConfigurationModalOpen} onClose={onCloseReportConfiguration} />
      )}
    </>
  );
};

ReportPanel.displayName = "ReportPanel";
export default ReportPanel;
