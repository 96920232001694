import React from "react";
import { Link } from "react-router-dom";
import { Business, HolidayVillage, PowerInput, SquareFoot } from "@mui/icons-material";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/query";
import { sumBy } from "lodash";

import { useGetMultiProjectStatisticsQuery } from "fond/api";
import { DrawBoundaryIcon } from "fond/svg_icons";
import { area } from "fond/turf";
import { MultiProject, MultiProjectArea } from "fond/types";
import { sqMetresToSqKilometers, sqMetresToSqMiles } from "fond/utils/area";
import { formatCompactNumber } from "fond/utils/number";

import CityPlannerInfoCard from "./CityPlannerInfoCard";

interface IProps {
  id?: string;
  project: MultiProject;
}

const CityPlannerInfoGrid: React.FC<IProps> = ({ id, project }) => {
  const { data: statistics, isLoading } = useGetMultiProjectStatisticsQuery(id ?? skipToken);

  const noSubarea = project.Areas.length === 0;
  const addressCount = sumBy(statistics?.Areas, "AddressCounts.AddressCount");
  const pathCount = sumBy(statistics?.Areas, "StreetCounts.StreetIntersectionLengthMetres");

  let totalArea = 0;
  project.Areas.forEach((subarea: MultiProjectArea) => {
    totalArea += area(subarea.Boundary);
  });
  // Convert the total area to the proper unit.
  totalArea = project.SystemOfMeasurement === "imperial" ? sqMetresToSqMiles(totalArea) : sqMetresToSqKilometers(totalArea);

  const cardData = [
    { title: "Subareas", icon: <Business color="secondary" />, value: formatCompactNumber(project.Areas.length) },
    { title: "Total premises", icon: <HolidayVillage color="secondary" />, value: formatCompactNumber(addressCount) },
    {
      title: "Total path",
      icon: <PowerInput color="secondary" />,
      value: formatCompactNumber(pathCount),
      unit: project.SystemOfMeasurement === "imperial" ? "feet" : "meters",
    },
    {
      title: "Total area",
      icon: <SquareFoot color="secondary" />,
      value: formatCompactNumber(totalArea),
      unit: project.SystemOfMeasurement === "imperial" ? "sq mi" : "km²",
    },
  ];

  if (noSubarea) {
    return (
      <Grid item lg={5} xs={12}>
        <Grid item xs={12} sx={{ height: "100%" }}>
          <Paper
            variant="elevation"
            elevation={1}
            square={false}
            sx={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
          >
            <DrawBoundaryIcon />
            <Typography variant="h3" sx={{ mt: 3 }}>
              No subareas
            </Typography>
            <Typography component="p" sx={{ mb: 2 }}>
              This market lens project has no subareas created.
            </Typography>
            <Button variant="contained" component={Link} to={`/city/${id ?? ""}`}>
              Start drawing subareas
            </Button>
          </Paper>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid item lg={5} xs={12}>
      <Grid container spacing={2.5} sx={{ height: "100%" }}>
        {cardData.map((data) => (
          <CityPlannerInfoCard key={data.title} {...data} isLoading={isLoading} />
        ))}
      </Grid>
    </Grid>
  );
};

export default CityPlannerInfoGrid;
