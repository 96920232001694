import React, { useMemo } from "react";
import { AttachMoney, Business, MonetizationOn, MultipleStop } from "@mui/icons-material";
import { Grid } from "@mui/material";

import { Report } from "fond/types/report";
import { formatCompactNumber } from "fond/utils/number";

import CityPlannerInfoCard from "../CityPlanner/CityPlannerInfoCard";

interface IProps {
  filteredNodes: Report[];
}

const CityReportCallout: React.FC<IProps> = ({ filteredNodes }: IProps) => {
  const rowSummary: {
    subareaCount: number | null;
    totalPassings: number | null;
    netCost: number | null;
    netRevenue: number | null;
  } = useMemo(() => {
    return {
      subareaCount: filteredNodes.length,
      totalPassings: filteredNodes.reduce((acc, row) => acc + (row?.TotalPassings ?? 0), 0),
      netCost: filteredNodes.reduce((acc, row) => acc + (row?.NetCost ?? 0), 0),
      netRevenue: filteredNodes.reduce((acc, row) => acc + (row?.NetRevenue ?? 0), 0),
    };
  }, [filteredNodes]);

  const reportCallouts = useMemo(() => {
    return [
      {
        key: "subareaCount",
        title: "Subareas",
        icon: <Business color="secondary" />,
        value: formatCompactNumber(rowSummary.subareaCount),
      },
      {
        key: "totalPassings",
        title: "Passings",
        icon: <MultipleStop color="secondary" />,
        value: formatCompactNumber(rowSummary.totalPassings),
      },
      { key: "netCost", title: "Cost", icon: <AttachMoney color="secondary" />, value: formatCompactNumber(rowSummary.netCost) },
      { key: "netRevenue", title: "Revenue", icon: <MonetizationOn color="secondary" />, value: formatCompactNumber(rowSummary.netRevenue) },
    ];
  }, [rowSummary]);

  return (
    <Grid item xs={12}>
      <Grid container spacing={2.5}>
        {reportCallouts.map(({ key, title, icon, value }) => (
          <CityPlannerInfoCard key={key} title={title} icon={icon} value={value} cellHeight={128} />
        ))}
      </Grid>
    </Grid>
  );
};

export default CityReportCallout;
