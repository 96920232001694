import { Suspense, SuspenseProps } from "react";

import { BlockSpinner } from "fond/widgets";

export const SuspenseSpinner: React.FC<SuspenseProps> = ({ children, ...props }: SuspenseProps) => {
  return (
    <Suspense fallback={<BlockSpinner containerProps={{ height: "100%" }} />} {...props}>
      {children}
    </Suspense>
  );
};
