import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";

import { selectFolderById, selectProjectsByParentId, useGetMultiProjectsQuery, useGetProjectsQuery } from "fond/api";
import { Store } from "fond/types";

import ProjectList from "../ProjectList/ProjectList";

interface RouteParams {
  multiProjectFolderId: string;
}

/**
 * A main page content displaying market lens projects.
 * Includes a DataGrid supporting column sorting and pagination
 */
const CityPlannerList: React.FC = () => {
  const { multiProjectFolderId } = useParams<keyof RouteParams>();
  const folder = useSelector((state: Store) => (multiProjectFolderId ? selectFolderById(state, multiProjectFolderId) : undefined));
  const { isLoading: isLoadingMultiProjects, isFetching } = useGetMultiProjectsQuery(undefined);
  const { isLoading: isLoadingProjects } = useGetProjectsQuery(undefined);
  const items = useSelector((state: Store) => (multiProjectFolderId ? selectProjectsByParentId(state, multiProjectFolderId) : undefined)) || [];
  const isLoading = isLoadingMultiProjects || isLoadingProjects;

  return (
    <>
      <Box mt={5} mb={2}>
        <Typography variant="h3" component="span" fontWeight={700}>
          Summary
        </Typography>
      </Box>
      <Paper square>
        <ProjectList searchText="" items={isLoading ? null : items} folder={folder} isLoading={isLoading || isFetching} />
      </Paper>
    </>
  );
};

export default CityPlannerList;
