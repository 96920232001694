import { TreeInformation, TreeItem, TreeItemRenderContext } from "react-complex-tree";
import { ChevronRight } from "@mui/icons-material";

import { ExpandIcon } from "./Tree.styles";

interface IProps<T> {
  item: TreeItem<T>;
  context: TreeItemRenderContext;
  info: TreeInformation;
}

const ItemArrow = <T,>({ item, context, info }: IProps<T>): JSX.Element => {
  const handleOnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (item.isFolder) {
      context.toggleExpandedState();
    }
  };

  return (
    <button
      {...context.arrowProps}
      type="button"
      aria-hidden="false"
      onClick={handleOnClick}
      data-testid="expand-icon"
      style={{ width: "var(--rct-arrow-container-size)", padding: 0, background: "none", border: "none", outline: "none" }}
    >
      <ExpandIcon isOpen={context.isExpanded === true}>{item.isFolder && <ChevronRight />}</ExpandIcon>
    </button>
  );
};

export default ItemArrow;
