import React, { useState } from "react";
import { ColDef } from "@ag-grid-community/core";
import { Box, Typography } from "@mui/material";

import { Attachment } from "fond/types";

import FileViewerModal from "./FileViewerModal";
import GalleryView from "./GalleryView";
import ListView from "./ListView";
import { Sort } from "./types";

interface IProps {
  files: Attachment[];
  view: "list" | "gallery";
  listViewColumnDefs?: ColDef[];
  listViewPagination?: boolean;
  selected: Attachment[];
  onSelectionChange(files: Attachment[]): void;
  emptyState?: React.ReactNode;
  actions?: React.ReactNode;
}

const FilesViewer: React.FC<IProps> = ({
  files,
  view,
  listViewColumnDefs,
  listViewPagination = true,
  selected,
  onSelectionChange,
  emptyState = null,
  actions,
}) => {
  const [isZoomOpen, setIsZoomOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const [sort, setSort] = useState<Sort | null>(null);

  if (files.length === 0) return emptyState;

  const handleZoomOpen = (index: number) => {
    setInitialSlide(index);
    setIsZoomOpen(true);
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-between" alignItems="center" py={1} px={1.5}>
        <Typography variant="body3" fontSize={14}>
          {files.length} {files.length > 1 ? "files" : "file"}
        </Typography>
        <Box>{actions}</Box>
      </Box>
      <Box flex={1}>
        {view === "list" ? (
          <ListView
            files={files}
            onSelectionChange={onSelectionChange}
            columnDefs={listViewColumnDefs}
            pagination={listViewPagination}
            selected={selected}
            sort={sort}
            setSort={setSort}
            handleZoomOpen={handleZoomOpen}
          />
        ) : (
          <GalleryView files={files} selected={selected} onSelectionChange={onSelectionChange} handleZoomOpen={handleZoomOpen} sort={sort} />
        )}
      </Box>
      <FileViewerModal files={files} initialSlide={initialSlide} onClose={() => setIsZoomOpen(false)} open={isZoomOpen} />
    </Box>
  );
};

export default FilesViewer;
