import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CloudDownload, Delete, Edit, Launch } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { skipToken } from "@reduxjs/toolkit/dist/query";

import { selectCurrentAllocation, useGetAccountSubscriptionsQuery } from "fond/api";
import { Attachment, AttachmentEntityType, SubscriptionStatus } from "fond/types";
import { Actions, licenseCheck } from "fond/utils/permissions";
import { BlockSpinner } from "fond/widgets";

import { IconButton } from "./AttachmentListToolbarActions.styles";

interface IProps {
  /**
   * The current datagrid row item selection.
   */
  selectedItems: Attachment[];
  /**
   * Callback function for when a single row is selected and the open button is clicked.
   */
  onOpen?(attachment: Attachment): void;
  /**
   * Callback function for when a single row is selected and the edit button is clicked.
   */
  onEdit?(attachment: Attachment): void;
  /**
   * Callback function for when the download button is clicked.
   */
  onDownload?(attachments: Attachment[]): void;
  /**
   * Callback function for when the delete button is clicked.
   */
  onDelete?(attachments: Attachment[]): void;
  /**
   * The level at which the attachment will be performed
   */
  attachmentEntityType?: AttachmentEntityType;
}

const AttachmentListToolbarActions: React.FC<IProps> = ({
  selectedItems,
  onOpen,
  onDownload,
  onEdit,
  onDelete,
  attachmentEntityType = "Project",
}: IProps) => {
  const [downloading, setDownloading] = useState(false);
  const currentAllocation = useSelector(selectCurrentAllocation);
  const { data: subscriptions } = useGetAccountSubscriptionsQuery(currentAllocation?.Account.ID ?? skipToken);
  const hasActiveSubscription = subscriptions?.some((subscription) => subscription.Status === SubscriptionStatus.Active);
  const editAttachmentAction = attachmentEntityType === "Project" ? Actions.PROJECT_EDIT_ATTACHMENT : Actions.FEATURE_EDIT_ATTACHMENT;
  const hasValidLicense = licenseCheck(currentAllocation, editAttachmentAction) && hasActiveSubscription;

  // Disable edit and open when multiple rows are selected
  const multipleSelection = selectedItems.length > 1;

  return (
    <>
      {onOpen && (
        <Tooltip title="Open in Browser">
          <span>
            <IconButton
              data-testid="attachment-list-toolbar-actions-open"
              aria-label="open"
              disabled={multipleSelection}
              onClick={() => onOpen(selectedItems[0])}
              color="primary"
            >
              <Launch />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {onEdit && (
        <Tooltip title="Edit">
          <span>
            <IconButton
              data-testid="attachment-list-toolbar-actions-edit"
              aria-label="rename"
              disabled={multipleSelection || !hasValidLicense}
              onClick={() => onEdit(selectedItems[0])}
              color="primary"
            >
              <Edit />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {onDownload && (
        <Tooltip title="Download">
          <span>
            <IconButton
              data-testid="attachment-list-toolbar-actions-download"
              aria-label="download"
              onClick={async () => {
                setDownloading(true);
                await onDownload(selectedItems);
                setDownloading(false);
              }}
              color="primary"
            >
              {downloading ? <BlockSpinner containerProps={{ minHeight: "unset" }} spinnerProps={{ size: 22 }} /> : <CloudDownload />}
            </IconButton>
          </span>
        </Tooltip>
      )}
      {onDelete && (
        <Tooltip title="Delete">
          <span>
            <IconButton
              data-testid="attachment-list-toolbar-actions-delete"
              aria-label="delete"
              disabled={!hasValidLicense}
              onClick={() => onDelete(selectedItems)}
              color="primary"
            >
              <Delete />
            </IconButton>
          </span>
        </Tooltip>
      )}
    </>
  );
};

AttachmentListToolbarActions.displayName = "AttachmentListToolbarActions";
export default AttachmentListToolbarActions;
