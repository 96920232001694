import { ObjectValues } from "./generic";

type Module<T extends AccountModuleName | UserModuleName> = {
  Name: T;
  Title: string;
  Description?: string;
};

/**
 * All available account modules
 */
export const AccountModuleName = {
  FlexNap: "flexnap",
  SpliceTables: "splice_tables",
  ImportReport: "financial_analytics_report_import",
  AttachmentsBulkImport: "attachments_bulk_import",
  DualSided: "dual_sided",
  ParcelExport: "parcel_export",
  AddressExport: "address_export",
} as const;
export type AccountModuleName = ObjectValues<typeof AccountModuleName>;
export type AccountModule = Module<AccountModuleName>;

/**
 * All available user modules
 */
export const UserModuleName = {
  FondAdmin: "fond_admin",
} as const;
export type UserModuleName = ObjectValues<typeof UserModuleName>;
export type UserModule = Module<UserModuleName>;
