import { createEntityAdapter, createSelector, EntityState } from "@reduxjs/toolkit";

import { apiSlice } from "fond/api/apiSlice";
import { Store, Workflow } from "fond/types";

import { multiProjectsSlice } from "./multiProjectsSlice";

type GetWorkflowsResponse = {
  Workflows: Workflow[];
};

export const workflowsAdaptor = createEntityAdapter<Workflow>({
  // Note that we are using the Project ID as the entity ID.
  // There can never be more than one workflow per project
  selectId: (entity: Workflow): string => entity.Version.Project.ID,
});
const workflowsInitialState = workflowsAdaptor.getInitialState();

export const workflowSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getWorkflows: build.query<EntityState<Workflow>, void>({
      query: () => "/v2/workflows",
      transformResponse: (response: GetWorkflowsResponse) => workflowsAdaptor.setAll(workflowsInitialState, response.Workflows),
    }),
  }),
});

export const { useGetWorkflowsQuery } = workflowSlice;

const selectWorkflowsResult = workflowSlice.endpoints.getWorkflows.select();
export const selectWorkflowsData = createSelector(selectWorkflowsResult, (workflowsResult) => workflowsResult.data);

export const {
  selectAll: selectAllWorkflows,
  selectById: selectWorkflowByProjectId,
  selectEntities: selectWorkflowEntities,
} = workflowsAdaptor.getSelectors((state: Store) => selectWorkflowsData(state) ?? workflowsInitialState);

/**
 * Determines the current total progress of subarea projects currently being created for
 * a MultiProject.
 *
 * Note that there are two different status checks required
 * 1) MultiProjectArea.ImportStatus indicates if the MultIProjectArea Project is currently being created.
 * 2) Workflow Status indicates if the MultiProjectArea Project is running a design.
 *
 * @returns {number | null} A fractional percentage value indicating the current progress.
 */
export const selectMultiProjectWorkflowProgress = createSelector(
  [selectWorkflowEntities, (state) => state, (_: Store, multiProjectId: string) => multiProjectId],
  (workflows, state: Store, multiProjectId) => {
    const multiProject = createSelector(multiProjectsSlice.endpoints.getMultiProject.select(multiProjectId), (result) => result.data)(state);

    // Get a list of projects within this MultiProject that are being created or running a design
    const projects = multiProject?.Areas.filter(({ Project, ImportStatus }) => {
      const status = Project && workflows[Project.ID]?.Status;
      const finishedStates = ["ERROR", "COMPLETE"];

      // Check if the MultiProjectArea project is running a design.
      if (status?.State) {
        return Project && status && !finishedStates.includes(status.State);
      }

      // Fallback to checking if the MultiProjectArea Project is being created.
      return ImportStatus?.State && !finishedStates.includes(ImportStatus.State);
    });

    // Check if we have no projects currently in the process of being created.
    if (!projects || projects.length === 0 || Object.keys(workflows).length === 0) return null;

    // Determine the total progress based on the number of projects being created asynchronously.
    let progress = 0;
    projects?.forEach(({ Project }) => {
      if (Project) {
        const status = workflows[Project.ID]?.Status;
        progress += status?.Progress || 0;
      }
    });

    return progress / (projects?.length || 1);
  }
);
