import React, { useMemo } from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { meanBy, sumBy } from "lodash";

import { Table, TableBody, TableCell, TableHead, TableRow } from "ui";

import { GetMultiProjectAreasStatisticsResponse } from "fond/api/multiProjectsSlice";
import { Report } from "fond/types/report";
import { formatCurrency } from "fond/utils/currency";
import { formatFractionPercent } from "fond/utils/number";

interface IProps {
  filteredNodes: Report[];
  statistics: GetMultiProjectAreasStatisticsResponse;
}

const CityReportStatistics: React.FC<IProps> = ({ filteredNodes, statistics }: IProps) => {
  const filteredStatistics = useMemo(() => {
    return statistics.Areas.filter((area) => filteredNodes.some((node) => node.MultiProjectArea?.ID === area.Area.ID));
  }, [filteredNodes, statistics]);

  const {
    sduProportionLocal: sduProportion,
    weightedMedianIncomeLocal: weightedMedianIncome,
    underservedProportionLocal: underservedProportion,
    attCoverageLocal: attCoverage,
    tMobileCoverageLocal: tMobileCoverage,
    verizonCoverageLocal: verizonCoverage,
  } = useMemo(() => {
    const weightedMedianIncomeLocal =
      filteredStatistics.length > 0 ? sumBy(filteredStatistics, "WeightedMedianIncome.WeightedMedianIncomeDollars") / filteredStatistics.length : 0;
    const sduProportionLocal = meanBy(filteredStatistics, "AddressBreakdown.Sdu.Proportion");
    const underservedProportionLocal = meanBy(filteredStatistics, "AddressUnderservedProportion.AddressUnderservedProportion");
    const attCoverageLocal = meanBy(
      filteredStatistics.filter((area) => area.ProviderFiberCoverage?.["AT&T"]?.FiberCoverageProportion !== undefined),
      "ProviderFiberCoverage.AT&T.FiberCoverageProportion"
    );
    const tMobileCoverageLocal = meanBy(
      filteredStatistics.filter((area) => area.ProviderFiberCoverage?.["T-Mobile"]?.FiberCoverageProportion !== undefined),
      "ProviderFiberCoverage.T-Mobile.FiberCoverageProportion"
    );
    const verizonCoverageLocal = meanBy(
      filteredStatistics.filter((area) => area.ProviderFiberCoverage?.Verizon?.FiberCoverageProportion !== undefined),
      "ProviderFiberCoverage.Verizon.FiberCoverageProportion"
    );

    return {
      sduProportionLocal,
      weightedMedianIncomeLocal,
      underservedProportionLocal,
      attCoverageLocal,
      tMobileCoverageLocal,
      verizonCoverageLocal,
    };
  }, [filteredStatistics]);

  const statisticRows = [
    { title: "Median Income", value: formatCurrency(weightedMedianIncome) },
    { title: "Underserved proportion", value: formatFractionPercent(underservedProportion) },
    { title: "SDU Proportion", value: formatFractionPercent(sduProportion) },
    { title: "AT&T Provider Coverage", value: formatFractionPercent(attCoverage) },
    { title: "T-Mobile Provider Coverage", value: formatFractionPercent(tMobileCoverage) },
    { title: "Verizon Provider Coverage", value: formatFractionPercent(verizonCoverage) },
  ];

  return (
    <Grid item xs={12} sx={{ height: 344 }}>
      <Paper variant="elevation" elevation={1} square={false} sx={{ display: "flex", flexDirection: "column", height: "100%", p: 3 }}>
        <Typography variant="h6">Statistics</Typography>
        <Box sx={{ mt: 1 }}>
          <Table variant="ag-grid-compact">
            <TableHead>
              <TableRow>
                <TableCell>Criteria</TableCell>
                <TableCell>Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {statisticRows.map((row) => (
                <TableRow key={row.title}>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Paper>
    </Grid>
  );
};
export default CityReportStatistics;
